<template>
  <v-container fluid>
    <v-layout align-center justify-center fill-height row wrap>
      <v-flex md6>
        <v-form ref="form" lazy-validation>
          <v-card flat>
            <v-card-title>
              <img class="login-logo" :src="logo" :height="100" :width="100" />
              <h1>Manager</h1>
              <h2 class="title">
                Nous contacter
              </h2>
            </v-card-title>

            <v-card-text v-if="done">
              <v-flex xs12 md8 offset-md2 class="text-xs-center">
                <v-alert
                  :value="true"
                  color="success"
                  icon="mdi-check-circle-outline"
                >
                  Message envoyé avec succès.
                </v-alert>
              </v-flex>
            </v-card-text>
            <v-card-text v-else>
              <v-flex xs12 md8 offset-md2 class="text-xs-center">
                <v-alert
                  :value="true"
                  color="warning"
                  icon="mdi-information-outline"
                >
                  Les champs comportant un astérisque sont requis.
                </v-alert>
              </v-flex>
              <v-flex xs12 md8 offset-md2>
                <v-text-field
                  v-model.trim="$v.contactMsg.company.$model"
                  :label="
                    'Entreprise' + (contactMsg.company.length <= 0 ? '*' : '')
                  "
                  prepend-icon="mdi-domain"
                  :error-messages="companyErrors"
                  autofocus
                />
              </v-flex>
              <v-flex xs12 md8 offset-md2>
                <v-text-field
                  v-model.trim="$v.contactMsg.lastname.$model"
                  :label="'Nom' + (contactMsg.lastname.length <= 0 ? '*' : '')"
                  prepend-icon="mdi-face"
                  :error-messages="lastnameErrors"
                />
              </v-flex>
              <v-flex xs12 md8 offset-md2>
                <v-text-field
                  v-model.trim="$v.contactMsg.firstname.$model"
                  :label="
                    'Prénom' + (contactMsg.firstname.length <= 0 ? '*' : '')
                  "
                  prepend-icon="mdi-/"
                  :error-messages="firstnameErrors"
                />
              </v-flex>
              <v-flex xs12 md8 offset-md2>
                <v-text-field
                  v-model.trim="$v.contactMsg.phone.$model"
                  :label="
                    'Numéro de téléphone' +
                      (contactMsg.phone.length <= 0 ? '*' : '')
                  "
                  prepend-icon="mdi-phone"
                  :error-messages="phoneErrors"
                />
              </v-flex>
              <v-flex xs12 md8 offset-md2>
                <v-text-field
                  v-model.trim="$v.contactMsg.email.$model"
                  :label="'Email' + (contactMsg.email.length <= 0 ? '*' : '')"
                  prepend-icon="mdi-at"
                  :error-messages="emailErrors"
                />
              </v-flex>
              <v-flex xs12 md8 offset-md2>
                <v-text-field
                  v-model.trim="contactMsg.object"
                  label="Sujet"
                  prepend-icon="mdi-subtitles-outline"
                />
              </v-flex>
              <v-flex xs12 md8 offset-md2>
                <v-textarea
                  v-model.trim="$v.contactMsg.content.$model"
                  :label="
                    'Détail' + (contactMsg.content.length <= 0 ? '*' : '')
                  "
                  prepend-icon="mdi-/"
                  :error-messages="contentErrors"
                />
              </v-flex>
              <v-flex xs12 md8 offset-md2 class="text-xs-center">
                <v-alert
                  :value="true"
                  color="warning"
                  icon="mdi-information-outline"
                >
                  Uniquement les fichiers faisant moins de 25 Mo et ayant les
                  extensions suivantes sont acceptés. <br />
                  (.gif, .jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx,
                  .odt, .csv)
                </v-alert>
              </v-flex>
              <v-flex xs12 md8 offset-md3>
                <ui-FileWidget
                  :files="contactMsg.files"
                  @fileUploaded="setFileToken($event)"
                />
              </v-flex>
              <v-flex v-show="false" xs12 md8 offset-md2>
                <v-text-field
                  v-model.trim="$v.contactMsg.honey.$model"
                  label="Nom"
                />
              </v-flex>
            </v-card-text>

            <v-card-actions v-if="done">
              <v-layout wrap align-center justify-center>
                <v-flex xs12 md5 left class="text-xs-center">
                  <v-btn
                    center
                    :loading="loading"
                    class="quaternary"
                    @click="login()"
                  >
                    <v-icon>mdi-arrow-left</v-icon>
                    <v-flex class="ml-2 pa-0">
                      Retour
                    </v-flex>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>
            <v-card-actions v-else>
              <v-layout wrap align-center justify-center>
                <v-flex xs12 md5 left class="text-xs-center">
                  <v-btn
                    center
                    :loading="loading"
                    class="quaternary"
                    @click="login()"
                  >
                    <v-icon>mdi-arrow-left</v-icon>
                    <v-flex class="ml-2 pa-0">
                      Annuler
                    </v-flex>
                  </v-btn>
                </v-flex>
                <v-flex xs12 md5 right class="text-xs-center">
                  <v-btn
                    color="success"
                    center
                    :loading="loading"
                    :disabled="$v.contactMsg.$invalid || loading"
                    @click="sendMessage()"
                  >
                    <v-icon>mdi-send</v-icon>
                    <v-flex class="ml-2 pa-0">
                      Envoyer
                    </v-flex>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>

    <ui-SnackBarV2
      :text="text"
      :color="color"
      :bottom="bottom"
      :top="top"
      :right="right"
      :left="left"
      :snackbar="snackbar"
    />
  </v-container>
</template>

<script>
// Utilities
import SnackBarMixin from '@/mixins/SnackBarMixin';
import { mapMutations, mapActions } from 'vuex';
import { required, maxLength, email } from 'vuelidate/lib/validators';

export default {
  name: 'Contact',

  // components:{Snackbar},
  mixins: [SnackBarMixin],

  data: () => ({
    loading: false,
    contactMsg: {
      company: '',
      lastname: '',
      firstname: '',
      phone: '',
      email: '',
      object: '',
      content: '',
      files: [],
      honey: '',
    },
    done: false,
  }),

  validations: {
    contactMsg: {
      company: {
        required,
      },
      lastname: {
        required,
      },
      firstname: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
      content: {
        required,
      },
      honey: {
        maxLength: maxLength(0),
      },
    },
  },

  computed: {
    // ...mapState('app', ['snackColor','snackText']),

    logo() {
      return this.$store.state.app.logo;
    },

    companyErrors() {
      const errors = [];
      if (!this.$v.contactMsg.company.$dirty) return errors;
      !this.$v.contactMsg.company.required &&
        errors.push("Le nom d'entreprise est obligatoire");
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.contactMsg.lastname.$dirty) return errors;
      !this.$v.contactMsg.lastname.required &&
        errors.push('Le prénom est obligatoire');
      return errors;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.contactMsg.firstname.$dirty) return errors;
      !this.$v.contactMsg.firstname.required &&
        errors.push('Le nom est obligatoire');
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.contactMsg.phone.$dirty) return errors;
      !this.$v.contactMsg.phone.required &&
        errors.push('Le numéro de téléphone est obligatoire');
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.contactMsg.email.$dirty) return errors;
      !this.$v.contactMsg.email.required &&
        errors.push("L'adresse mail est obligatoire");
      !this.$v.contactMsg.email.email &&
        errors.push('Entrez une adresse email valide');
      return errors;
    },
    contentErrors() {
      const errors = [];
      if (!this.$v.contactMsg.content.$dirty) return errors;
      !this.$v.contactMsg.content.required &&
        errors.push('Le détail est obligatoire');
      return errors;
    },
  },

  mounted() {},

  methods: {
    ...mapActions('contact', ['create_contact']),
    ...mapMutations('app', ['showLoader', 'showSnack']),

    setFileToken(filesToken) {
      this.contactMsg.files = filesToken;
    },

    login() {
      this.$router.push({ path: `/` });
    },

    sendMessage() {
      console.log(this.contactMsg);
      if (this.contactMsg.honey !== '' || this.$v.contactMsg.$invalid) {
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: 'Le formulaire comporte une erreur',
        });
      } else {
        this.showLoader();
        this.create_contact(this.contactMsg)
          .then((response) => {
            console.log('Projects OK', response);
            this.done = true;
            this.snack({
              position: 'bottom',
              align: 'center',
              color: 'success',
              text: 'Message envoyé avec succès',
            });
          })
          .catch((error) => {
            console.log('Erreur', error);
            this.snack({
              position: 'bottom',
              align: 'center',
              color: 'error',
              text: "Impossible d'envoyé le message : " + error.message,
            });
          })
          .finally(() => {
            this.showLoader();
          });
      }
    },
  },
};
</script>
<style scoped>
#app .container,
div#app,
#core-view {
  background: #fff !important;
  height: 100vh !important;
}
h1,
h2 {
  text-align: center !important;
  width: 100%;
}
.v-input--selection-controls {
  margin-top: 0;
}
</style>
